import * as Sentry from '@sentry/remix'
/**
 * By default, Remix will handle hydrating your app on the client for you.
 * You are free to delete this file if you'd like to, but if you ever want it revealed again, you can run `npx remix reveal` ✨
 * For more information, see https://remix.run/file-conventions/entry.client
 */

import { RemixBrowser, useLocation, useMatches } from '@remix-run/react'
import React, { startTransition, StrictMode, useEffect } from 'react'
import { hydrateRoot } from 'react-dom/client'

Sentry.init({
 dsn: 'https://5b9d19656ba4c4b5bd13a4483adbc998@o4508090488127488.ingest.us.sentry.io/4508091425423360',
 tracesSampleRate: 1,

 integrations: [
  Sentry.browserTracingIntegration({
   useEffect,
   useLocation,
   useMatches,
  }),
  Sentry.replayIntegration({
   maskAllText: true,
   blockAllMedia: true,
  }),
 ],

 replaysSessionSampleRate: 0.1,
 replaysOnErrorSampleRate: 1,
})

startTransition(() => {
 hydrateRoot(
  document,
  // <StrictMode>
  <RemixBrowser />
  // </StrictMode>
 )
})
